<template>
    <div>
        <b-modal v-model="isOpenModal" id="deleteAccountModal" :hide-header="true"
                 :hide-footer="true">
                <p>Are you sure you want to delete your account? </p>
                <div class="modal-footer d-flex mr-2 px-0 gap-3">
                    <button type="button" :disabled="isDeleteAccount" class="btn main-btn col m-0" @click="handleSubmitDeleteAccount">{{ $t('title.yesDelete') }}</button>
                    <button type="button" class="btn sec-btn col m-0" @click="cancelDeleteAccount">
                        {{ $t('title.cancel') }}
                    </button>
                </div>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { removeCookie } from "@/Util/auth";
import {request} from "@/Util/Request";


export default {
    data() {
        return {
            isOpenModal: false,
            isDeleteAccount: false
        };
    },
    methods: {
        handleToggleModal() {
            this.isOpenModal = !this.isOpenModal;
        },
        async handleSubmitDeleteAccount() {
            this.isDeleteAccount = true;
            let role = 'user';
            try {
                const response = await request({
                    method: 'DELETE',
                    url: `/mobile/users/${this.user.id}`,
                });

                const { data } = response;
                removeCookie('auth');
                removeCookie('platform');
                role = this.user?.role;

                await this.$store.dispatch('user', null);
                await this.$router.replace({ name: (role == 'admin') ? 'adminLogin' : 'login' });
                this.handleToggleModal();
                this.isDeleteAccount = false;
            } catch (error) {
                this.isDeleteAccount = false;
                if(error.request && error.request.status && error.request.status !== 401) {
                    this.notifyErrorWithMsg(JSON.parse(error.request.responseText).message);
                }
            }
        },
        cancelDeleteAccount() {
            this.isOpenModal = false;
        }
    },
    computed: {
        ...mapGetters(['user'])
    }
};
</script>
